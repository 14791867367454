<template>
  <v-card
    class="docs-card pa-3 ma-3"
  >
    <v-card-title class="card-title coolgray--text font-weight-medium">
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-col
        xs12
      >
        <v-row
          v-for="(doc, key) in docLinks"
          :key="key"
        >
          <a
            :href="doc.url"
            target="_blank"
          >
            {{ doc.title }}
          </a>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('docs')

export default {
  name: 'DocCard',
  props: {
    title: {
      default: null,
      type: String,
    },
    type: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapAuthGetters(['isInternalUser']),
    ...mapState({
      links: (state) => state.links,
    }),
    docLinks() {
      const { links, type } = this

      return links[type] || []
    },
  },
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      const { isInternalUser, type } = this
      const data = { isInternalUser, type }
      const links = await this.$store.dispatch('docs/getLinksByType', data)
      const docs = { links, type }

      this.$store.commit('docs/links', docs)

      return docs
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";
</style>
